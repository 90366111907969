import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';
import UserModalMobile from '../../../components/user/UserModalMobile';

import {
    setUserObject,
    setUserResponse,
    setSaveButtonDisabled,
    setOpenUserMobile
} from '../../actions/userActions';

import { onSubmit } from '../../workers/userWorker';

const mapStateToProps = state => {
    const { UserReducers, FacilityReducers } = state;

    if (UserReducers) {
        return {
            user: UserReducers.user,
            facilityList: FacilityReducers.facilities,
            isModalMobile: UserReducers.isModalMobile,
            errorMessage: UserReducers.userResponse,
            saveButtonDisabled: UserReducers.saveButtonDisabled
        };
    }

    return {};
};

const UserModalMobileContainer = withTheme(
    connect(
        mapStateToProps, {
            onSubmit,
            setSaveButtonDisabled,
            setUserResponse,
            setUserObject,
            setOpenUserMobile
        }
    )(UserModalMobile)
);

export default UserModalMobileContainer;
