import React, { useEffect } from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { localize } from '../../util/Localizer';
import commonUseStyles from '../../styles/CommonStyles';
import UserForm from './UserForm/UserForm';

const ResultMobile = styled('div')(() => ({
    marginBottom: 20,
    marginTop: 295,
    paddingLeft: 15,
    paddingRight: 15
}));

const ResultContentMobile = styled(Paper)(() => ({
    ...commonUseStyles.gridContent,
    paddingLeft: 0
}));

const HeaderMobile = styled('div')(({ theme }) => ({
    borderRadius: '10px 10px 0 0',
    background: theme.palette.everDrivenDarkBlue,
    height: 40,
    justifyContent: 'space-between',
    display:'flex'
}));

const HeaderTitleMobile = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.white,
    paddingLeft: 20,
    paddingTop: 10
}));

const CloseButtonMobile = styled('div')(() => ({
    float: 'right'
}));

const ModalCloseButtonMobile = styled(IconButton)(({ theme }) => ({
    color: theme.palette.grey[500]
}));

function UserModalMobile({
    user,
    facilityList,
    isModalMobile: isOpen,
    errorMessage,
    onSubmit,
    setSaveButtonDisabled,
    setUserResponse: setMessage,
    saveButtonDisabled,
    setUserObject: setUser,
    setOpenUserMobile: openMobileForm
}) {
    const onSubmitForm = (e) => {
        e.preventDefault();
        onSubmit();
    };

    const handleClose = () => {
        openMobileForm(false);
    };

    useEffect(() =>{
        setMessage('');
    }, [isOpen]);

    return isOpen && (
        <ResultMobile>
            <ResultContentMobile>
                <HeaderMobile>
                    <HeaderTitleMobile>
                        {localize('addUser.title')}
                    </HeaderTitleMobile>
                    <CloseButtonMobile>
                        <ModalCloseButtonMobile
                            onClick={() => handleClose()}
                            size="large"
                        >
                            <CloseIcon />
                        </ModalCloseButtonMobile>
                    </CloseButtonMobile>
                </HeaderMobile>
                <UserForm
                    facilityList={facilityList}
                    user={user}
                    setSaveButtonDisabled={setSaveButtonDisabled}
                    setErrorAlert={setMessage}
                    setUser={setUser}
                    onSubmitForm={onSubmitForm}
                    nameForm="userMobileForm"
                    errorMessage={errorMessage}
                    saveButtonDisabled={saveButtonDisabled}
                    bottonIdName="SubmitMobileAddForm"
                    buttonLabel={localize('editUser.SaveChanges')}
                />
            </ResultContentMobile>
        </ResultMobile>
    );
}
export default UserModalMobile;
