import React from 'react';
import { Grid, FormControlLabel } from '@mui/material';
import { localize } from '../../util/Localizer';
import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';

function PopulationType({ populationTypes, setPopulationTypes, setIsSameTripInfo }) {
    const handleChangePopulationTypes = (evt) => {
        if (evt === null) {
            return false;
        }

        const { name, type } = evt.target;
        let { value } = evt.target;

        if (type === 'checkbox') {
            value = evt.target.checked;
        }
        setIsSameTripInfo(false);
        setPopulationTypes({
            [name]: value
        });

        return true;
    };

    return (
        <Grid container>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeMKV"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeMKV"
                        color="secondary"
                        isChecked={populationTypes.populationTypeMKV}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeMKV')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeNCLB"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeNCLB"
                        color="secondary"
                        isChecked={populationTypes.populationTypeNCLB}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeNCLB')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeSpecialEvents"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeSpecialEvents"
                        color="secondary"
                        isChecked={populationTypes.populationTypeSpecialEvents}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeSpecialEvents')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeESE"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeESE"
                        color="secondary"
                        isChecked={populationTypes.populationTypeESE}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeESE')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeRegularEd"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeRegularEd"
                        color="secondary"
                        isChecked={populationTypes.populationTypeRegularEd}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeRegularEd')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeSchoolofChoice"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeSchoolofChoice"
                        color="secondary"
                        isChecked={populationTypes.populationTypeSchoolofChoice}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeSchoolofChoice')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeESY"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeESY"
                        color="secondary"
                        isChecked={populationTypes.populationTypeESY}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeESY')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeMagnet"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeMagnet"
                        color="secondary"
                        isChecked={populationTypes.populationTypeMagnet}
                        size="medium"
                        onChange={handleChangePopulationTypes} />}
                    label={localize('tripRequestForm.populationTypeMagnet')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeESSA"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeESSA"
                        color="secondary"
                        isChecked={populationTypes.populationTypeESSA}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeESSA')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeATP"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeATP"
                        color="secondary"
                        isChecked={populationTypes.populationTypeATP}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeATP')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeOutofDistrict"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeOutofDistrict"
                        color="secondary"
                        isChecked={populationTypes.populationTypeOutofDistrict}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeOutofDistrict')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeMultiDistrict"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeMultiDistrict"
                        color="secondary"
                        isChecked={populationTypes.populationTypeMultiDistrict}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeMultiDistrict')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeSPED"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeSPED"
                        color="secondary"
                        isChecked={populationTypes.populationTypeSPED}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeSPED')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeDeafEd"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeDeafEd"
                        color="secondary"
                        isChecked={populationTypes.populationTypeDeafEd}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeDeafEd')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="populationTypeMedFragile"
                    size="medium"
                    control={<StyledCheckbox
                        name="populationTypeMedFragile"
                        color="secondary"
                        isChecked={populationTypes.populationTypeMedFragile}
                        size="medium"
                        onChange={handleChangePopulationTypes}
                    />}
                    label={localize('tripRequestForm.populationTypeMedFragile')}
                />
            </Grid>
        </Grid>
    );
}

export default PopulationType;
