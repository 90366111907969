import history from '../../history';
import {
    getEndpointFormatDate,
    isToday
} from '../../util/dateUtils';
import {
    Enum,
    indexReportName
} from '../../util/EnumHelper';
import mrmFetch from '../../util/fetchUtil';
import { downloadFile } from '../../util/fileUtil';
import { localize } from '../../util/Localizer';
import { objectEmptyValidator } from '../../util/validationUtil';

import {
    setLoadingCall,
    loadResponseReport,
    setMessageRow,
    setViewButton,
    setDownloadButton,
    setCardElementsReport,
    setDatePicker
} from "../actions/reportActions";
import { updatePath } from './pathWorker';

import {
    closeStyledSnackBar,
    openStyledSnackBar,
    setSnackbar
} from './styledSnackbarWrapperWorker';

export const loadReport = (report) => async (dispatch) => {
    dispatch(setLoadingCall(true));
    dispatch(closeStyledSnackBar());

    const reportResponse = await mrmFetch(`/api/v2/Report?reportName=${report.index}
        &requestType=${Enum.reportRequestType.View}`, 'get');

    window.log(`StudentOnBoarding Json Objects`, reportResponse);

    if (!objectEmptyValidator(reportResponse)) {
        dispatch(loadResponseReport(reportResponse));
        dispatch(updatePath(report.url));
    } else {
        dispatch(
            setMessageRow(
                report.index,
                localize(report.messages.loadErrorMessage)
            )
        );
        dispatch(setViewButton(report.index, true));
    }

    dispatch(setLoadingCall(false));
};

export const downloadEndYearReport = (report) => async (dispatch) => {
    dispatch(setLoadingCall(true));

    const responseMessage = await mrmFetch(
        '/api/v2/EndYearReport',
        'get',
        null,
        null,
        true
    );

    if (responseMessage === '') {
        dispatch(
            setMessageRow(
                report.index,
                localize(report.messages.downloadErrorMessage)
            ));
        dispatch(setDownloadButton(report.index, true));
    } else {
        downloadFile(responseMessage);

        dispatch(setSnackbar(
            localize("report.reportDownloaded"),
            Enum.severityType.success
        ));
        dispatch(openStyledSnackBar());
    }

    dispatch(setLoadingCall(false));
};

export const resolveStudentOnBoardingReport = (report, isFromInsideReport) => async (dispatch) => {
    dispatch(setLoadingCall(true));

    const responseMessage = await mrmFetch(`/api/v2/Report?reportName=${report.index}
        &requestType=${Enum.reportRequestType.Download}`, 'get', null, null, true);

    if (responseMessage === '') {
        if (isFromInsideReport) {
            dispatch(setSnackbar(
                localize("report.excelFileNotFound"),
                Enum.severityType.error
            ));
            dispatch(openStyledSnackBar());
        } else {
            dispatch(
                setMessageRow(
                    report.index,
                    localize(report.messages.downloadErrorMessage)
                ));
            dispatch(setDownloadButton(report.index, true));
        }
    } else {
        downloadFile(responseMessage);

        dispatch(setSnackbar(
            localize("report.reportDownloaded"),
            Enum.severityType.success
        ));
        dispatch(openStyledSnackBar());
    }

    dispatch(setLoadingCall(false));
};

export const downloadReport = (report, isFromInsideReport) => async (dispatch) => {
    dispatch(closeStyledSnackBar());

    switch (report.index) {
    case indexReportName.NoShowReport:
        dispatch(setDatePicker(true));
        break;
    case indexReportName.EndYearReport:
        dispatch(downloadEndYearReport(report));
        break;
    case indexReportName.StudentOnboardingReport:
        dispatch(resolveStudentOnBoardingReport(report, isFromInsideReport));
        break;
    default:
        break;
    }
};

export const downloadNoShowReport = (reportDate) => async (dispatch) => {
    const date = getEndpointFormatDate(reportDate);

    dispatch(setLoadingCall(true));

    dispatch(closeStyledSnackBar());

    const responseMessage = await mrmFetch(
        `/api/v2/NoShowReport?reportDate=${date}`,
        'get',
        null,
        null,
        true
    );

    if (responseMessage === '') {
        dispatch(setSnackbar(
            localize("report.noShowNoReport"),
            Enum.severityType.error
        ));
    } else {
        downloadFile(responseMessage);

        const message = isToday(reportDate)
            ? localize("report.reportDownloaded")
            : localize("report.noShowDifferentDayDownloaded");

        dispatch(setSnackbar(
            message,
            Enum.severityType.success
        ));
    }
    dispatch(openStyledSnackBar());
    dispatch(setLoadingCall(false));
};

export const closeNoShowModalDatePicker = () => (dispatch) => {
    dispatch(setDatePicker(false));
};

let controller = null;

export const fetchStudentReport = (studentName) => {
    // Abort the old one (it's fine if we haven't sent it yet)
    if(controller) {
        controller.abort();
    }
    // for ie
    const {AbortController} = window;

    controller = new AbortController();

    return async (dispatch) => {
        dispatch(setLoadingCall(true));
        const path = `/api/v2/Student?query=${studentName}`;

        const students = await mrmFetch(path, 'get', controller.signal);

        window.log(`found students`, students);

        if (students.length) {
            const student = students[0];
            const tripListUrl = `/triplist/${student.id}`;

            history.push(tripListUrl);
            dispatch(closeStyledSnackBar());
        } else if (studentName) {
            dispatch(setSnackbar(
                localize("report.studentNotFound"),
                Enum.severityType.error
            ));
            dispatch(openStyledSnackBar());
        }

        dispatch(setLoadingCall(false));
    };
};

export const resetReponses = () => (dispatch) => {
    dispatch(closeStyledSnackBar());

    dispatch(setMessageRow(indexReportName.StudentOnboardingReport, ''));
    dispatch(setMessageRow(indexReportName.NoShowReport, ''));

    dispatch(setDownloadButton(indexReportName.StudentOnboardingReport, false));
    dispatch(setDownloadButton(indexReportName.NoShowReport, false));
    
    dispatch(setViewButton(indexReportName.StudentOnboardingReport, false));
    dispatch(setViewButton(indexReportName.NoShowReport, false));
};

function filter(e, term) {
    const param = term.trim();
    let studentName = '';

    if (e.StudentName !== null) {
        studentName = e.StudentName.toLowerCase();
    }

    return studentName.indexOf(param) > -1;
}

export const filterCards = () => (dispatch, getState) => {
    const { reportModel, term } = getState().ReportReducers;

    const cardElements = reportModel.elements
        ? reportModel.elements.filter((e) => filter(e, term))
        : [];
    
    dispatch(setCardElementsReport(cardElements));
};
