import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import StudentOnBoardingReport from '../../../components/report/StudentOnBoardingReport/StudentOnBoardingReport';
import { setTermReport } from '../../actions/reportActions';
import {
    loadReport,
    downloadReport,
    fetchStudentReport,
    filterCards,
    resetReponses
} from '../../workers/reportsWorker';
import { closeStyledSnackBar } from '../../workers/styledSnackbarWrapperWorker';
import { indexReportName, reports } from '../../../util/EnumHelper';
import { accessPathMiddleware } from '../../workers/pathWorker';
import withRouter from '../../../util/customHooks/withRouter';

const mapStateToProps = (state) => {
    const { ReportReducers } = state;

    if (ReportReducers) {
        return {
            report: reports[indexReportName.StudentOnboardingReport],
            lastModfiedDate: ReportReducers.reportModel.lastModified ?? '',
            cardElements: ReportReducers.cardElements,
            term: ReportReducers.term,
            isLoadingReport: ReportReducers.isLoadingReport
        };
    }

    return {};
};

const StudentOnboardingReportContainer = withRouter(
    connect(
        mapStateToProps, {
            loadReport,
            closeSnackBar: closeStyledSnackBar,
            downloadReport,
            setTermReport,
            fetchStudentReport,
            filterCards,
            accessPathMiddleware,
            resetReponses
        }
    )(injectIntl(StudentOnBoardingReport)));

export default StudentOnboardingReportContainer;
