import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import Isvg from "react-inlinesvg";

import StyledCardContainer from 'components/Common/StyledCardContainer.js/StyledCardContainer';
import { monthDayYearFormat } from 'util/dateUtils';
import Auth from 'auth/Auth';
import StyledButton from 'components/Common/StyledButton/StyledButton';

import StudentOnBoardingCardDetail from "components/report/StudentOnBoardingCardDetail/StudentOnBoardingCardDetail";
import PropTypes from 'prop-types';

const HeaderBox = styled('div')(({ theme }) => ({
    borderBottom: `1px ${theme.palette.white} solid`,
    cursor: 'pointer',
    padding: '16px 0px 8px',
    '&> :first-of-type': {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: 'bold',
        wordBreak: 'break-word'
    },
    '&> *': {
        fontSize: '12px'
    }
}));

const StyledInsertInvitationIcon = styled(InsertInvitationIcon)(() => ({
    width: 12,
    height: 12,
    marginBottom: -2
}));

const ContentBox = styled('div')(() => ({
    cursor: 'pointer',
    padding: '4px 0',
    '&> :first-of-type': {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column'
    }
}));

const StyledLessButton = styled(StyledButton)(() => ({
    background: 'transparent',
    border: 'none',
    minWidth: 32,
    width: 32,
    height: 20,
    '&:focus, &:active': {
        outline: 'none'
    }
}));

const OptionListSvg = styled(Isvg)(() => ({
    minWidth: '20px',
    width: '20px'
}));

const StudentNameBox = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

function StudentOnBoardingCard({
    card,
    id,
    groupType,
    onHandleStudentSearch
}) {
    const theme = useTheme();

    const [ isExtended, setIsExtended ] = useState(false);

    const headerColor = {
        inProgress: theme.palette.lightBlueOption1,
        active: theme.palette.greenOption1,
        waiting: theme.palette.everDrivenFuchsia,
        onHold: theme.palette.grayOption1
    };
    
    const hasAccessDttButton = () => (Auth.isProfessionalAgent() || Auth.hasTripTrackingAccess()
                                    && Auth.getConfig().facilities.find(facility => facility.name === card.School));

    const renderCardHeader = () => (
        <>
            <HeaderBox>
                <StudentNameBox>
                    <span className="qm_block">{`${card.StudentName}`.trim()}</span>
                    { groupType==='active' && hasAccessDttButton() &&
                            <StyledLessButton
                                testId={`goToStudentButton_${id}_${groupType}`}
                                variant="text"
                                onClick={() => onHandleStudentSearch(card.StudentName)}
                            >
                                <OptionListSvg src="/img/option_list.svg" />
                            </StyledLessButton>
                    }
                </StudentNameBox>
                <div>{`${card.School}`.trim()}</div>
                <div>
                    <StyledInsertInvitationIcon/>
                    {`${monthDayYearFormat(card.StartDate ? card.StartDate?.value: '')}`.trim()}
                </div>
            </HeaderBox>
            <ContentBox>
                <div>
                    <span><b>{card.Status}</b></span>
                </div>
            </ContentBox>
        </>
    );

    return (
        <StyledCardContainer
            headerColor={headerColor[groupType]}
            cardHeader={renderCardHeader()}
            cardBody={<StudentOnBoardingCardDetail card={card} isExtended={isExtended} />}
            onClickHeader={() => setIsExtended(!isExtended)}
            footerStyles = {{padding: '0 8px'}}
        />
    );
}

StudentOnBoardingCard.defaultProps = {
    card: {},
    id: '',
    groupType: '',
    onHandleStudentSearch: () => {}
};

/* eslint-disable react/forbid-prop-types */
StudentOnBoardingCard.propTypes = {
    card: PropTypes.object,
    id: PropTypes.string,
    groupType: PropTypes.string,
    onHandleStudentSearch: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default StudentOnBoardingCard;
