/* eslint max-lines: 0 */
import _ from 'lodash';
import { searchStudent } from './districtTripTracker/dttStudentWorker';
import { Enum } from "../../util/EnumHelper";
import mrmFetch, { mrmFetchFormData } from "../../util/fetchUtil";
import readFile from '../../util/fileUtil';
import { localize } from "../../util/Localizer";
import { getPathTabValue } from "../../util/updatePathTab";
import { isJsonString, objectEmptyValidator } from '../../util/validationUtil';
import {
    clearDraftIndicator,
    setDeleteDraftModalStatus,
    setIsDraftForSave,
    setKeyDropZone,
    setOldDraftStudentAttachments,
    setSearchTerm,
    setStudentAttachments,
    setTripRequestFormLoading,
    updateOldDraftModel
} from "../actions/tripRequestFormActions";
import { updatePath } from "./pathWorker";
import { onAddForm } from "./tripRequestFormWorker";

import {
    closeStyledSnackBar,
    openStyledSnackBar,
    setSnackbar
} from './styledSnackbarWrapperWorker';
import { fixedEncodeURIComponent } from '../../util/textFormatters';

const errorDraft = 'tripRequestForm.draftError';
const FILES_NOT_FOUND_DRAFT = 'tripRequestForm.draftFilesNotFound';

const formatDraftObject = (objectElements) => ({
    studentId: fixedEncodeURIComponent(objectElements.tripModel.studentId),
    studentFirstName: fixedEncodeURIComponent(objectElements.tripModel.studentFirstName.trim()),
    studentLastName: fixedEncodeURIComponent(objectElements.tripModel.studentLastName.trim()),
    correlationId: objectElements.tripModel.correlationId,
    tripModel: {
        ...objectElements.tripModel,
        studentId: fixedEncodeURIComponent(objectElements.tripModel.studentId),
        studentFirstName: fixedEncodeURIComponent(objectElements.tripModel.studentFirstName.trim()),
        studentLastName: fixedEncodeURIComponent(objectElements.tripModel.studentLastName.trim())
    },
    populationTypes: objectElements.populationTypes,
    transportationEquipmentNeeds: objectElements.transportationEquipmentNeeds,
    showDifferentAddress: objectElements.showDifferentAddress,
    schedule: objectElements.schedule,
    scheduleValid: objectElements.scheduleValid,
    schoolDays: objectElements.schoolDays
});

export const onGetDraftAttachments = (studentAttachments) => async (dispatch) => {
    const fileAttachments = [];
    let nProcess = 0;
    let nErrors = 0;

    try {
        await Promise.all(studentAttachments.map(async (studentAttachment) => {
            if (studentAttachment) {
                const fileBody = {
                    attachment: studentAttachment
                };

                const fileResponse = await mrmFetch(
                    `/api/v2/DraftAttachment`,
                    'POST',
                    null,
                    JSON.stringify(fileBody),
                    true
                );
                
                if (fileResponse === '') {
                    nErrors += 1;
                } else {
                    if (isJsonString(fileResponse.fileName)) {
                        fileResponse.fileName = JSON.parse(fileResponse.fileName);
                    }

                    const attachFile = new File([fileResponse.blob], fileResponse.fileName, {
                        type: fileResponse.blob.type
                    });

                    attachFile.path = fileResponse.fileName;
                    attachFile.dataPreview = await readFile(attachFile);
                    fileAttachments.push(attachFile);
                }
            } else {
                nErrors += 1;
            }
            
            nProcess += 1;
            if (nProcess === studentAttachments.length) {
                if (nErrors > 0) {
                    dispatch(setSnackbar(
                        `${nErrors} ${localize(FILES_NOT_FOUND_DRAFT)}`,
                        Enum.severityType.error
                    ));
                    dispatch(openStyledSnackBar());
                }

                dispatch(setOldDraftStudentAttachments(fileAttachments));
                dispatch(setStudentAttachments(fileAttachments));
                dispatch(setKeyDropZone(Math.random().toString()));
                dispatch(setTripRequestFormLoading(false));
            }
        }));
    } catch (error) {
        dispatch(setSnackbar(
            localize(FILES_NOT_FOUND_DRAFT),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());

        dispatch(setTripRequestFormLoading(false));
        window.warn(`error in onGetDraftAttachments`,
            error
        );
    }
};

export const onSaveDraftBody = (draftObject, isBeforeLeaving) => async dispatch => {
    const body = JSON.stringify(draftObject);

    try {
        const draftResponse = await mrmFetch(`/api/v2/DraftRequest`,
            'post',
            null,
            body
        );
    
        if (draftResponse.studentAttachments) {
            dispatch(setStudentAttachments([]));
            dispatch(setKeyDropZone(Math.random().toString()));
            dispatch(setSnackbar(
                localize('tripRequestForm.draftSuccess'),
                Enum.severityType.success
            ));
            dispatch(setIsDraftForSave(false));
            dispatch(setTripRequestFormLoading(false));
        } else {
            dispatch(setSnackbar(
                localize(errorDraft),
                Enum.severityType.error
            ));

            dispatch(setTripRequestFormLoading(false));
        }
        
        dispatch(onAddForm());
        dispatch(openStyledSnackBar());
        dispatch(setSearchTerm(""));
        
        // This is to prevent clearing a possible search on DTT when leaving TRF
        if (!isBeforeLeaving) {
            dispatch(searchStudent('', true));
        }

        setTimeout(
            () => {
                dispatch(closeStyledSnackBar());
            },
            2000
        );
    } catch (error) {
        dispatch(setTripRequestFormLoading(false));

        dispatch(setSnackbar(
            localize(errorDraft),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());

        window.warn(`error in onSaveDraftBody`,
            error
        );
    }
};

export const onUploadDraftFiles = (model, files, uploadedFiles, isBeforeLeaving) => async (dispatch) => {
    let indexProcess = 1;
    const requestData = model;

    requestData.studentAttachments = [];

    // eslint-disable-next-line
    for (const file of files) {
        try {
            if (!uploadedFiles.find(x => x.split('/')[4] === file.name)) {
                const formData = new FormData();

                formData.append('file',
                    file
                );

                // eslint-disable-next-line
                const resp = await mrmFetchFormData(`/api/v2/RequestFile?isDraft=true`,
                    formData,
                    'post');

                requestData.studentAttachments.push(resp.url);
            } else {
                // eslint-disable-next-line
                const urlFile = uploadedFiles.find(x => x.split('/')[4] === file.name)

                requestData.studentAttachments.push(urlFile);
            }
            if (indexProcess === files.length) {
                dispatch(onSaveDraftBody(requestData, isBeforeLeaving));
            }
            indexProcess += 1;
        } catch (e) {
            dispatch(setTripRequestFormLoading(false));

            dispatch(setSnackbar(
                localize(errorDraft),
                Enum.severityType.error
            ));
            dispatch(openStyledSnackBar());

            window.warn('error in onUploadDraftFiles',
                e
            );
        }
    }
};

export const onSaveDraft = () => async (dispatch, getState) => {
    const {
        attachStudentDocuments,
        populationTypes,
        schedule,
        scheduleValid,
        schoolDays,
        showDifferentAddress,
        transportationEquipmentNeeds,
        tripModel,
        uploadedDraftAttachments
    } = getState().TripRequestFormReducers;

    const draftObject = formatDraftObject({
        tripModel,
        populationTypes,
        transportationEquipmentNeeds,
        schedule,
        scheduleValid,
        schoolDays,
        showDifferentAddress
    });

    if ((tripModel.studentDateOfBirth && Number.isNaN(tripModel.studentDateOfBirth.getTime()))
        || (tripModel.tripStartDate && Number.isNaN(tripModel.tripStartDate.getTime()))
        || (tripModel.tripEndDate && Number.isNaN(tripModel.tripEndDate.getTime()))) {
        dispatch(setSnackbar(
            localize('tripRequestForm.invalidDateAlertSavingDraft'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());
                
        return false;
    }

    dispatch(setTripRequestFormLoading(true));
    dispatch(updateOldDraftModel(tripModel));

    if (attachStudentDocuments.length === 0) {
        dispatch(onSaveDraftBody(draftObject));
    } else {
        dispatch(onUploadDraftFiles(
            draftObject,
            attachStudentDocuments,
            uploadedDraftAttachments
        ));
    }

    return true;
};

export const onValidDraftIsSaved = () => (dispatch, getState) => {
    const {
        attachStudentDocuments,
        isDraftForSave,
        tripModel,
        oldDraftAttachments,
        oldDraftModel,
        oldDraftPopulationTypes,
        populationTypes,
        tripModels
    } = getState().TripRequestFormReducers;

    if (((isDraftForSave && objectEmptyValidator(oldDraftModel))
        || (tripModels.find(x => x.isDraftForSave && objectEmptyValidator(x.oldDraftModel))))
        || ((!objectEmptyValidator(oldDraftModel) && isDraftForSave && (!_.isEqual(tripModel, oldDraftModel)
        || !_.isEqual(attachStudentDocuments, oldDraftAttachments)
        || !_.isEqual(populationTypes, oldDraftPopulationTypes)))
        || tripModels.find(x => !objectEmptyValidator(x.oldDraftModel) && x.isDraftForSave
        && (!_.isEqual(x.tripModel, x.oldDraftModel)
        || !_.isEqual(x.attachStudentDocuments, x.oldDraftAttachments)
        || !_.isEqual(x.populationTypes, x.oldDraftPopulationTypes))))) {
        return true;
    }

    return false;
};

export const onSaveDraftBeforeLeave = (nextPath) => async (dispatch, getState) => {
    const {
        attachStudentDocuments,
        currentSibling,
        isDraftForSave,
        oldDraftAttachments,
        oldDraftModel,
        oldDraftPopulationTypes,
        populationTypes,
        schedule,
        scheduleValid,
        schoolDays,
        showDifferentAddress,
        transportationEquipmentNeeds,
        tripModel,
        tripModels,
        uploadedDraftAttachments
    } = getState().TripRequestFormReducers;

    dispatch(setTripRequestFormLoading(true));

    try {
        if ((isDraftForSave && objectEmptyValidator(oldDraftModel)
            || (!objectEmptyValidator(oldDraftModel) && isDraftForSave && (!_.isEqual(tripModel, oldDraftModel)
            || !_.isEqual(attachStudentDocuments, oldDraftAttachments)
            || !_.isEqual(populationTypes, oldDraftPopulationTypes))))) {
            const draftObject = formatDraftObject({
                tripModel,
                populationTypes,
                transportationEquipmentNeeds,
                schedule,
                scheduleValid,
                schoolDays,
                showDifferentAddress
            });
    
            if (attachStudentDocuments.length === 0) {
                dispatch(onSaveDraftBody(draftObject, true));
            } else {
                dispatch(onUploadDraftFiles(
                    draftObject,
                    attachStudentDocuments,
                    uploadedDraftAttachments,
                    true
                ));
            }
        }
        
        await Promise.all(tripModels.filter(x => x.student !== currentSibling).map(async (model) => {
            if ((model.isDraftForSave && objectEmptyValidator(model.oldDraftModel)
            || (!objectEmptyValidator(model.oldDraftModel) && model.isDraftForSave
            && (!_.isEqual(model.tripModel, model.oldDraftModel)
            || !_.isEqual(model.attachStudentDocuments, model.oldDraftAttachments)
            || !_.isEqual(model.populationTypes, model.oldDraftPopulationTypes))))) {
                const newDraftModel = formatDraftObject(model);
                
                if (model.attachStudentDocuments.length === 0) {
                    dispatch(onSaveDraftBody(newDraftModel, true));
                } else {
                    dispatch(onUploadDraftFiles(
                        newDraftModel,
                        model.attachStudentDocuments,
                        model.uploadedDraftAttachments,
                        true
                    ));
                }
            }
        }));
    } catch (error) {
        dispatch(setSnackbar(
            localize(errorDraft),
            Enum.severityType.error
        ));

        window.warn('error in onSaveDraftBeforeLeave',
            error
        );
    }

    dispatch(setTripRequestFormLoading(false));
    dispatch(clearDraftIndicator());
    dispatch(updatePath(getPathTabValue(nextPath)));
};

export const onDeleteDraft = () => async (dispatch, getState) => {
    const { tripModel } = getState().TripRequestFormReducers;

    dispatch(setDeleteDraftModalStatus(false));
    dispatch(setTripRequestFormLoading(true));

    try {
        const resp = await mrmFetch(
            `/api/v2/DeleteDraft?query=${fixedEncodeURIComponent(tripModel.studentId)}`,
            'delete'
        );

        if (resp === true) {
            dispatch(setSnackbar(
                localize('tripRequestForm.deleteDraftSuccess'),
                Enum.severityType.success
            ));
            dispatch(setStudentAttachments([]));
            dispatch(setKeyDropZone(Math.random().toString()));
            dispatch(onAddForm());
        } else {
            dispatch(setSnackbar(
                localize('tripRequestForm.deleteDraftError'),
                Enum.severityType.error
            ));
        }
        
        dispatch(setTripRequestFormLoading(false));
        dispatch(searchStudent('', true));
        dispatch(openStyledSnackBar());
        setTimeout(
            () => {
                dispatch(closeStyledSnackBar());
            },
            2000
        );
    } catch (error) {
        dispatch(setDeleteDraftModalStatus(false));
        dispatch(setTripRequestFormLoading(false));
        dispatch(setSnackbar(
            localize('tripRequestForm.deleteDraftError'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());
        window.warn(`error in onDeleteDraft`,
            error
        );
    }
};
