import React from 'react';
import { styled } from '@mui/material/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { localize } from 'util/Localizer';
import { studentOnBoardingConstants } from 'redux/constants';
import PropTypes from 'prop-types';

const alwaysVisibleGroup = {
    padding: '8px 0px 0px',
    fontSize: 12,
    display: 'flex',
    '&> *': {
        paddingRight: 8,
        textOverflow:'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
};

const extendedPadding = {
    paddingBottom: 8
};

const BasicCard = styled('div')((props) => {
    const isExtended = /^true$/i.test(props?.isextended);

    return {
        ...alwaysVisibleGroup,
        ...(!isExtended && extendedPadding)
    };
});

const CheckBoxIconMinSize = styled(CheckBoxIcon)(() => ({
    width: 12,
    height: 12,
    marginBottom: -1
}));

const CheckBoxOutlineBlankIconMinSize = styled(CheckBoxOutlineBlankIcon)(() => ({
    width: 12,
    height: 12,
    marginBottom: -1
}));

const NoVisibleContentGroupBox = styled('div')(({ theme }) => ({
    padding: '8px 0px 4px',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    '&> *': {
        paddingBottom: 4
    },
    borderBottom: '1px solid',
    borderColor: theme.palette.black
}));

const NoVisibleGroupBox = styled('div')(() => ({
    padding: '8px 0px 4px',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    '&> *': {
        paddingBottom: 4
    }
}));

function StudentOnBoardingCardDetail({ card, isExtended }) {
    const boolDataIntake = card.DataIntake ?
        card.DataIntake.toString().trim() === studentOnBoardingConstants.DataIntakeAcceptedValue
        : false;
    const boolRouted = card.Routed ?
        card.Routed.toString().trim() === studentOnBoardingConstants.RoutedAcceptedValue
        : false;
    const boolParentGuardianStatus = card.ParentGuardianStatus ?
        card.ParentGuardianStatus.toString().trim() === studentOnBoardingConstants.ParentGuardianStatusAcceptedValue
        : false;
    const boolDriver = card.Driver ?
        card.Driver.toString().trim() === studentOnBoardingConstants.DriverAcceptedValue
        : false;

    return (
        <>
            <BasicCard isextended={isExtended?.toString()}>
                <span>{boolDataIntake ?
                    <CheckBoxIconMinSize /> :
                    <CheckBoxOutlineBlankIconMinSize />}
                {`  ${localize("report.data")}`}
                </span>
                <span>{boolRouted ?
                    <CheckBoxIconMinSize /> :
                    <CheckBoxOutlineBlankIconMinSize />}
                {`  ${localize("report.routed")}`}
                </span>
                <span>{boolDriver ?
                    <CheckBoxIconMinSize /> :
                    <CheckBoxOutlineBlankIconMinSize />}
                {`  ${localize("report.driver")}`}
                </span>
                <span>{boolParentGuardianStatus ?
                    <CheckBoxIconMinSize /> :
                    <CheckBoxOutlineBlankIconMinSize />}
                {`  ${localize("report.parent")}`}
                </span>
            </BasicCard>
            {isExtended && (
                <>
                    <NoVisibleContentGroupBox>
                        <div className="qm_block">
                            <b>{`${localize("report.id")}: `}</b>{card.StudentID}
                        </div>
                        <div className="qm_block">
                            <b>{`${localize("report.account")}: `}</b>{card.AccountName}
                        </div>
                    </NoVisibleContentGroupBox>
                    <NoVisibleContentGroupBox>
                        <div className="qm_block">
                            <b>{`${localize("report.AMPUAddress")}: `}</b>{card.AM_PU_Address}
                        </div>
                        <div className="qm_block">
                            <b>{`${localize("report.PMDOAddress")}: `}</b>{card.PM_DO_Address}
                        </div>
                    </NoVisibleContentGroupBox>
                    <NoVisibleContentGroupBox>
                        <div>
                            <b>{`${localize("report.parentContactNotes")}: `}</b>{card.ParentContactNotes}
                        </div>
                    </NoVisibleContentGroupBox>
                    <NoVisibleGroupBox>
                        <div className="qm_block">
                            <b>{`${localize("report.phone1")}: `}</b>{card.Phone1}
                        </div>
                        <div className="qm_block">
                            <b>{`${localize("report.phone2")}: `}</b>{card.Phone2}
                        </div>
                        <div className="qm_block">
                            <b>{`${localize("report.phone3")}: `}</b>{card.Phone3}
                        </div>
                        <div className="qm_block">
                            <b>{`${localize("report.phone4")}: `}</b>{card.Phone4}
                        </div>
                        <div className="qm_block">
                            <b>{`${localize("report.phone5")}: `}</b>{card.Phone5}
                        </div>
                    </NoVisibleGroupBox>
                </>
            )}
        </>
    );
}

StudentOnBoardingCardDetail.defaultProps = {
    isExtended: false
};

/* eslint-disable react/forbid-prop-types */
StudentOnBoardingCardDetail.propTypes = {
    card: PropTypes.object.isRequired,
    isExtended: PropTypes.bool
};
/* eslint-enable react/forbid-prop-types */

export default StudentOnBoardingCardDetail;
