import { connect } from 'react-redux';
import GeneralTripInformation from '../../../controls/TripRequestForm/GeneralTripInformation';
import {
    onUpdateModel
} from '../../workers/tripRequestFormWorker';
import {
    onUpdateTransportationEquipmentNeed,
    setIsSameTripInfo
} from '../../workers/tripRequestFormTripInformationWorker';
import {
    setPopulationTypes
} from '../../actions/tripRequestFormActions';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        tripModel: TripRequestFormReducers.tripModel,
        populationTypes: TripRequestFormReducers.populationTypes,
        transportationEquipmentNeeds: TripRequestFormReducers.transportationEquipmentNeeds,
        currentSibling: TripRequestFormReducers.currentSibling,
        isSameTripInformation: TripRequestFormReducers.isSameTripInformation
    };
};

const GeneralTripInformationContainer = connect(mapStateToProps,
    {
        onUpdateModel,
        onUpdateTransportationEquipmentNeed,
        setPopulationTypes,
        setIsSameTripInfo
    })(GeneralTripInformation);

export default GeneralTripInformationContainer;
