import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { array, bool, func } from 'prop-types';
import StyledLoading from 'components/Common/StyledLoading/StyledLoading';
import StyledBannerContent from 'components/Common/StyledBannerContent/StyledBannerContent';
import breakpointsNames from 'styles/ResponsiveConstants';
import { localize } from 'util/Localizer';
import UserRowContainer from 'redux/containers/user/UserRowContainer';
import UserModalMobileContainer from 'redux/containers/user/UserModalMobileContainer';

const UserListResult = styled('div')(({ theme }) => ({
    marginBottom: 20,
    marginTop: 240,
    paddingLeft: 15,
    paddingRight: 15,
    "@media only screen and (max-width: 1142px) and (min-width: 960px)": {
        marginTop: 276
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        marginTop: 200
    }
}));

const UserLoadingBox = styled('div')(({ theme }) => ({
    marginTop: 200,
    paddingRight: 15,
    paddingLeft: 15,
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        marginTop: 320
    }
}));

const UserResultFixed = styled('div')(() => ({
    width: '100%',
    zIndex: 0
}));

function UserBody({
    isLoadingUser,
    isModalMobile,
    userList,
    closeStyledSnackBar
}) {
    const renderNoResults = () => (
        <div>
            <StyledBannerContent
                title={localize('userManagement.noUserFound')}
                content={localize('userManagement.instructionsUser')}
                show={userList.length === 0 && !isModalMobile}
            />
        </div>
    );

    const renderUsers = () => {
        const result = userList.map(u => (
            <UserRowContainer
                user={u}
                editUser={u}
                key={u.id}
            />
        ));

        return (
            !isModalMobile && userList.length > 0 &&
                <UserListResult>
                    {result}
                </UserListResult>
        );
    };

    const renderResults = () => (
        !isLoadingUser && (
            <div>
                <UserModalMobileContainer />
                {renderUsers()}
                {renderNoResults()}
            </div>
        )
    );

    const renderLoading = () => (
        isLoadingUser && (
            <UserLoadingBox>
                <StyledLoading message={localize('userManagement.searchingForUsers')} />
            </UserLoadingBox>
        )
    );

    useEffect(() =>{
        closeStyledSnackBar();
    }, []);

    return (
        <UserResultFixed>
            <Grid item xs={12}>
                {renderResults()}
                {renderLoading()}
            </Grid>
        </UserResultFixed>
    );
}

UserBody.defaultProps = {
    isLoadingUser: true,
    isModalMobile: false,
    userList: [],
    closeStyledSnackBar: () => null
};

/* eslint-disable react/forbid-prop-types */
UserBody.propTypes = {
    isLoadingUser: bool,
    isModalMobile: bool,
    userList: array,
    closeStyledSnackBar: func
};

export default UserBody;