import 'react-app-polyfill/ie11';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import ErrorPage from 'screens/Error/ErrorPage';
import './polyfills/index';
import DefaultConfig from './DefaultConfig';
import configureStore from './redux/store';
import Analytics from './util/analytics/Analytics';
import './css/styles.css';
import enMessages from './intl/en.json';
import esMessages from './intl/es.json';
import Auth from './auth/Auth';
import CacheBuster from './CacheBuster';
import history from './history';
import LoadingPrerender from './widgets/common/LoadingPrerender';
import ConnectedApp from './App';
import muiTheme from './muiTheme';
import { getLanguage, localize } from './util/Localizer';

const useStyles = {
    root: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

const configJson = localStorage.getItem('config');
let prevConfig = {};

if (configJson) {
    prevConfig = JSON.parse(configJson);
}
window.Config = { ...DefaultConfig, ...prevConfig };

// Set up Localization
const language = getLanguage();
const messages = language === 'en' ? enMessages : esMessages;

function Root() {
    const [ configLoaded, setConfigLoaded ] = useState(false);
    const [ configWatcher, setConfigWatcher ] = useState(null);
    const store = configureStore(history);

    const watchConfig = () => {
        if (Auth.getConfig()?.configLoaded) {
            clearInterval(configWatcher);
            setConfigWatcher(null);
            setConfigLoaded(true);
        }
    };

    useEffect(() => {
        if (!configWatcher) {
            setConfigWatcher(setInterval(
                watchConfig,
                100
            ));
        }
    }, []);

    // We have an issue with live hosting where the http => https 301 is appending .html, while we look at server
    // side fixes, we'll just redirect them on the client.
    if (window.location.pathname.toLowerCase() === '/index.html') {
        return (
            <Provider store={store}>
                <ReduxRouter history={history}>
                    <Navigate to="/" replace />
                </ReduxRouter>
            </Provider>
        );
    }

    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) {
                    return null;
                }
                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                }

                return (
                    <Provider store={store}>
                        <IntlProvider
                            locale={language}
                            messages={messages}
                        >
                            <div>
                                <StyledEngineProvider injectFirst>
                                    <ThemeProvider theme={muiTheme}>
                                        <div>
                                            <Analytics config={Auth.getConfig()} history={history} />
                                            <ConnectedApp configLoaded={configLoaded} />
                                        </div>
                                    </ThemeProvider>
                                </StyledEngineProvider>
                            </div>
                        </IntlProvider>
                    </Provider>
                );
            }}
        </CacheBuster>
    );
}

function RootError() {
    const store = configureStore(history);

    return (
        <Provider store={store}>
            <IntlProvider
                locale={language}
                messages={messages}
            >
                <div>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={muiTheme}>
                            <ErrorPage
                                content=""
                                isOutOfContext
                                title={localize('errorPage.content403')}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            </IntlProvider>
        </Provider>
    );
}

const isRedirectPage =
	window.location.pathname.toLowerCase().indexOf('redirect') > -1;

if (!isRedirectPage) {
    window.info(`loading page with redirect of ${window.location.pathname}`);
    window.localStorage.setItem('redirect_to',
        window.location.pathname);
}

const container = document.getElementById('root');
const root = createRoot(container);

Auth.login(
    () => {
        window.log('We have logged in');
        const redirectTo = window.localStorage.getItem('redirect_to');

        if (isRedirectPage && redirectTo) {
            window.info(`We are redirecting to ${redirectTo}`);
            window.localStorage.removeItem('redirect_to');
            window.location = redirectTo;
        } else {
            window.log('Rendering normally');

            root.render(<Root />);
        }
    },
    () => {
        window.log("Sorry, it looks like you don't have permission to access the District Portal");
        root.render(<RootError />);
    },
    () => {
        root.render(
            <div style={useStyles.root}>
                <LoadingPrerender />
            </div>
        );
    }
);
