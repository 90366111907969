/* eslint max-lines: 0 */
import { getCorrelationId } from 'services/transportationRequestService';
import mrmFetch, {
    mrmFetchFormData
} from '../../util/fetchUtil';
import {
    tripRequestFormResponseFile,
    setTripRequestFormLoading,
    setSearchTerm,
    setDefaultStudentId,
    updateModel,
    setErrorMessage,
    setEnabledSubmit,
    setEnabledStudentDate,
    setSchoolDays,
    setTransportationNeeds,
    setShowDifferentAddress,
    setTripPopulationTypes,
    setStudentAttachments,
    setSchedule,
    setSubmitConfirmModalStatus,
    setSubmitFormMessage,
    setPopulationTypes,
    setKeyDropZone,
    setIsSameAddressCheck,
    setIsSameTripInfoCheck,
    setIsSameContactCheck,
    setIsSameSchoolCheck,
    setSelectedHomeAddress,
    setSelectedHomeAddress2,
    setSelectedPickUpAddress,
    setSelectedPickUpAddress2,
    setSelectedDropOffAddress,
    setSelectedDropOffAddress2,
    setSelectedSchoolAddress,
    setSelectedSchoolAddress2,
    setSelectedSchool,
    setSelectedStudent,
    setEmailErrors,
    setEnabledSchoolInput,
    setSelectedAccountHasNoEmail,
    setStudentStartDateErrorMessage,
    setStudentEndDateErrorMessage,
    setStudentBirthDateErrorMessage,
    setEnabledDraft,
    setIsDraftForSave,
    setUploadedDraftAttachments,
    cleanForm,
    updateOldDraftModel,
    setOldDraftStudentAttachments,
    setOldDraftPopulationTypes,
    setIsDraftSelected,
    setNonSchoolAddressList,
    setSelectedPreviousHomeAddress,
    setSelectedPreviousDropOffAddress,
    setSelectedPreviousPickUpAddress,
    setOldDraftTransportationNeeds,
    setCalledGetCorrelationId
} from '../actions/tripRequestFormActions';
import { Enum } from '../../util/EnumHelper';
import { localize } from '../../util/Localizer';
import {
    personalInformationModel,
    formatPlaceHolderPhoneNumber,
    generalTripInformationModel,
    requestTypeDto,
    schoolInformationModel,
    emergencyContactInformationModel,
    transportationEquipmentDto,
    populationTypeDto,
    schoolDaysDto,
    scheduleModel
} from '../../data/models/tripRequestForm/TripRequestFormModel';

import {
    closeStyledSnackBar,
    openStyledSnackBar,
    setSnackbar
} from './styledSnackbarWrapperWorker';
import {
    compareIsBefore,
    compareIsSameOrBefore,
    dateFormatDateOfBirth,
    dateIsValid,
    IsSameHours,
    minimumDateAllowed,
    timeFormat
} from '../../util/dateUtils';
import {
    validationPhoneNumber,
    isPhoneNumber
} from '../../util/validationUtil';
import Auth from '../../auth/Auth';
import { searchStudent } from './districtTripTracker/dttStudentWorker';
// eslint-disable-next-line import/no-cycle
import { onDeleteTab } from './tripRequestFormSiblingHeaderWorker';
// eslint-disable-next-line import/no-cycle
import { formatTransporationNeeds } from './tripRequestFormTripInformationWorker';
import { initialValues } from '../../util/data/dataDecorator';
import { validateEmail, stringValidate } from '../../util/textFormatters';

const errorLabel = 'tripRequestForm.messageError';

const formatPopulationTypeGeneralTripInfo = () => (dispatch, getState) => {
    const { populationTypes } = getState().TripRequestFormReducers;

    const pTypes = [];

    if (populationTypes.populationTypeMKV) {
        pTypes.push(Enum.populationTypes.MKV);
    }
    if (populationTypes.populationTypeESE) {
        pTypes.push(Enum.populationTypes.ESE);
    }
    if (populationTypes.populationTypeESY) {
        pTypes.push(Enum.populationTypes.ESY);
    }
    if (populationTypes.populationTypeATP) {
        pTypes.push(Enum.populationTypes.ATP);
    }
    if (populationTypes.populationTypeNCLB) {
        pTypes.push(Enum.populationTypes.NCLB);
    }
    if (populationTypes.populationTypeRegularEd) {
        pTypes.push(Enum.populationTypes.RegularEd);
    }
    if (populationTypes.populationTypeMedFragile) {
        pTypes.push(Enum.populationTypes.MedFragile);
    }
    if (populationTypes.populationTypeMagnet) {
        pTypes.push(Enum.populationTypes.Magnet);
    }
    if (populationTypes.populationTypeOutofDistrict) {
        pTypes.push(Enum.populationTypes.OutofDistrict);
    }
    if (populationTypes.populationTypeSPED) {
        pTypes.push(Enum.populationTypes.SPED);
    }
    if (populationTypes.populationTypeMultiDistrict) {
        pTypes.push(Enum.populationTypes.MultiDistrict);
    }
    if (populationTypes.populationTypeDeafEd) {
        pTypes.push(Enum.populationTypes.DeafEd);
    }
    if (populationTypes.populationTypeSpecialEvents) {
        pTypes.push(Enum.populationTypes.SpecialEvents);
    }
    if (populationTypes.populationTypeSchoolofChoice) {
        pTypes.push(Enum.populationTypes.SchoolofChoice);
    }
    if (populationTypes.populationTypeESSA) {
        pTypes.push(Enum.populationTypes.ESSA);
    }

    if (pTypes.length === 0) {
        pTypes.push(Enum.populationTypes.NOT);
    }

    dispatch(setTripPopulationTypes(pTypes));
};

const isValidTime = (flagDay, pickUpDate, arrivalDate) => (!flagDay || (flagDay &&
    ((pickUpDate && !(Number.isNaN(pickUpDate.getTime()))) ||
        !pickUpDate) && ((arrivalDate && !(Number.isNaN(arrivalDate.getTime()))) ||
            !arrivalDate) && (pickUpDate || arrivalDate)));

const parentAppAccess = (value, name) => (dispatch) => {
    if(!validateEmail(value)) {
        dispatch(updateModel({
            [name] : true
        }));
    } else {
        dispatch(updateModel({
            [name] : false
        }));
    }
};

export const isDraftCorrect = (isUpdate) => (dispatch, getState) => {
    const { tripModel } = getState().TripRequestFormReducers;
    let isEnabledDraft = false;

    isEnabledDraft = tripModel.studentId !== '' && tripModel.studentFirstName !== '' &&
        tripModel.studentLastName !== '';

    dispatch(setEnabledDraft(isEnabledDraft));

    if (isUpdate) {
        dispatch(setIsDraftForSave(isEnabledDraft));
    } else {
        dispatch(setIsDraftForSave(false));
    }
};

const hasRequiredAddressFields = (tripModel, key) => (
    tripModel[`${key}AddressLine1`] && tripModel[`${key}AddressCity`] &&
    tripModel[`${key}AddressZipCode`] && tripModel[`${key}AddressState`]
);

const validateAddresses = (tripModel) => {
    const results = [];

    if (tripModel.amPickupLocation === "Home Address" || tripModel.pmDropOffLocation === "Home Address") {
        results.push(hasRequiredAddressFields(tripModel, 'student'));
    }

    if (tripModel.amPickupLocation === "Different Address") {
        results.push(hasRequiredAddressFields(tripModel, 'pickupLocation'));
    }

    if (tripModel.pmDropOffLocation === "Different Address") {
        results.push(hasRequiredAddressFields(tripModel, 'dropOffLocation'));
    }

    return results.every(x => x);
};

export const isNewCorrect = () => (dispatch, getState) => {
    const { tripModel, scheduleValid, emailErrors } = getState().TripRequestFormReducers;
    let isEnabled = true;

    const inputTimesAreValid =
        scheduleValid.schoolPickupMonday && scheduleValid.schoolArrivalMonday &&
        scheduleValid.schoolPickupTuesday && scheduleValid.schoolArrivalTuesday &&
        scheduleValid.schoolPickupWednesday && scheduleValid.schoolArrivalWednesday &&
        scheduleValid.schoolPickupThursday && scheduleValid.schoolArrivalThursday &&
        scheduleValid.schoolPickupFriday && scheduleValid.schoolArrivalFriday &&
        scheduleValid.schoolPickupSaturday && scheduleValid.schoolArrivalSaturday &&
        scheduleValid.schoolPickupSunday && scheduleValid.schoolArrivalSunday;

    const isMondayValid = isValidTime(
        tripModel.schoolMonday,
        tripModel.schoolPickupMonday,
        tripModel.schoolArrivalMonday
    );

    const isTuesdayValid = isValidTime(
        tripModel.schoolTuesday,
        tripModel.schoolPickupTuesday,
        tripModel.schoolArrivalTuesday
    );

    const isWednesdayValid = isValidTime(
        tripModel.schoolWednesday,
        tripModel.schoolPickupWednesday,
        tripModel.schoolArrivalWednesday
    );

    const isThursdayValid = isValidTime(
        tripModel.schoolThursday,
        tripModel.schoolPickupThursday,
        tripModel.schoolArrivalThursday
    );

    const isFridayValid = isValidTime(
        tripModel.schoolFriday,
        tripModel.schoolPickupFriday,
        tripModel.schoolArrivalFriday
    );

    const isSaturdayValid = isValidTime(
        tripModel.schoolSaturday,
        tripModel.schoolPickupSaturday,
        tripModel.schoolArrivalSaturday
    );

    const isSundayValid = isValidTime(
        tripModel.schoolSunday,
        tripModel.schoolPickupSunday,
        tripModel.schoolArrivalSunday
    );

    const isValidAddresses = validateAddresses(tripModel);

    if (tripModel.requestType === requestTypeDto.update) {
        isEnabled = !(tripModel.studentId?.trim() &&
            tripModel.studentFirstName?.trim() &&
            tripModel.studentLastName?.trim() &&
            tripModel.studentDateOfBirth &&
            tripModel.parent1FirstName?.trim() &&
            tripModel.parent1Relationship?.trim() &&
            tripModel.parent1MobilePhone?.trim() &&
            isMondayValid && isTuesdayValid &&
            isWednesdayValid && isThursdayValid &&
            isFridayValid && isSaturdayValid && isSundayValid &&
            inputTimesAreValid);
    } else {
        isEnabled = !(tripModel.studentId?.trim() &&
            tripModel.studentFirstName?.trim() &&
            tripModel.studentLastName?.trim() &&
            tripModel.studentDateOfBirth &&
            tripModel.tripStartDate &&
            tripModel.tripEndDate &&
            tripModel.parent1FirstName?.trim() &&
            tripModel.parent1Relationship?.trim() &&
            tripModel.parent1MobilePhone?.trim() &&
            tripModel.parent1MobilePhone !== formatPlaceHolderPhoneNumber &&
            tripModel.schoolName?.trim() &&
            tripModel.schoolAddressLine1?.trim() &&
            tripModel.schoolAddressCity?.trim() &&
            tripModel.schoolAddressZipCode?.trim() &&
            tripModel.schoolAddressState?.trim() &&
            tripModel.schoolPhone?.trim() &&
            tripModel.schoolPhone !== formatPlaceHolderPhoneNumber &&
            isValidAddresses &&
            isMondayValid && isTuesdayValid && isWednesdayValid && isThursdayValid && isFridayValid &&
            isSaturdayValid && isSundayValid && inputTimesAreValid &&
            tripModel.transportationEquipmentNeeds ?
            tripModel.transportationEquipmentNeeds.length > 0 : false);
    }
        
    if(emailErrors.parent1EmailAddress) {
        isEnabled=emailErrors.parent1EmailAddress;
    }

    if(emailErrors.contact1EmailAddress) {
        isEnabled=emailErrors.contact1EmailAddress;
    }

    if(emailErrors.contact2EmailAddress) {
        isEnabled=emailErrors.contact2EmailAddress;
    }

    dispatch(setEnabledSubmit(isEnabled));
};

const formatPhoneNumbers = (tripModel) => {
    const tripRequestForm = tripModel;

    if (!validationPhoneNumber(tripModel.parent1MobilePhone)) {
        tripRequestForm.parent1MobilePhone = '';
    }
    if (!validationPhoneNumber(tripModel.parent1AltPhone)) {
        tripRequestForm.parent1AltPhone = '';
    }
    if (!validationPhoneNumber(tripModel.contact1MobilePhone)) {
        tripRequestForm.contact1MobilePhone = '';
    }
    if (!validationPhoneNumber(tripModel.contact2MobilePhone)) {
        tripRequestForm.contact2MobilePhone = '';
    }
    if (!validationPhoneNumber(tripModel.schoolPhone)) {
        tripRequestForm.schoolPhone = '';
    }
};

const convertToLocalDate = (ndate) => {
    if (!ndate) {
        return (ndate);
    }

    return (
        new Date(ndate.getUTCFullYear(),
            ndate.getUTCMonth(),
            ndate.getUTCDate(),
            ndate.getUTCHours(),
            ndate.getUTCMinutes(),
            ndate.getUTCSeconds())
    );
};

const formatDays = (tripModel) => {
    const tripRequestForm = tripModel;

    tripRequestForm.studentDateOfBirth = dateFormatDateOfBirth(tripModel.studentDateOfBirth);
    tripRequestForm.schoolArrivalMonday = timeFormat(convertToLocalDate(tripModel.schoolArrivalMonday));
    tripRequestForm.schoolArrivalTuesday = timeFormat(convertToLocalDate(tripModel.schoolArrivalTuesday));
    tripRequestForm.schoolArrivalWednesday = timeFormat(convertToLocalDate(tripModel.schoolArrivalWednesday));
    tripRequestForm.schoolArrivalThursday = timeFormat(convertToLocalDate(tripModel.schoolArrivalThursday));
    tripRequestForm.schoolArrivalFriday = timeFormat(convertToLocalDate(tripModel.schoolArrivalFriday));
    tripRequestForm.schoolArrivalSaturday = timeFormat(convertToLocalDate(tripModel.schoolArrivalSaturday));
    tripRequestForm.schoolArrivalSunday = timeFormat(convertToLocalDate(tripModel.schoolArrivalSunday));
    tripRequestForm.schoolPickupMonday = timeFormat(convertToLocalDate(tripModel.schoolPickupMonday));
    tripRequestForm.schoolPickupTuesday = timeFormat(convertToLocalDate(tripModel.schoolPickupTuesday));
    tripRequestForm.schoolPickupWednesday = timeFormat(convertToLocalDate(tripModel.schoolPickupWednesday));
    tripRequestForm.schoolPickupThursday = timeFormat(convertToLocalDate(tripModel.schoolPickupThursday));
    tripRequestForm.schoolPickupFriday = timeFormat(convertToLocalDate(tripModel.schoolPickupFriday));
    tripRequestForm.schoolPickupSaturday = timeFormat(convertToLocalDate(tripModel.schoolPickupSaturday));
    tripRequestForm.schoolPickupSunday = timeFormat(convertToLocalDate(tripModel.schoolPickupSunday));
    tripRequestForm.tripStartDate = !tripModel.tripStartDate ? '' : tripModel.tripStartDate;
    tripRequestForm.tripEndDate = !tripModel.tripEndDate ? '' : tripModel.tripEndDate;
};

const validationSchoolDays = (startDate, endDate, keyRequired, keyRange, nameDay) => {
    const response = {
        message: '',
        isValid: true
    };

    if (startDate === null && endDate === null) {
        response.message = localize(`tripRequestForm.${keyRequired}`);
        response.isValid = false;
    } else if (!compareIsBefore(startDate,
        endDate)) {
        if (!((startDate && endDate === null) || (startDate === null && endDate))) {
            response.message = localize(`tripRequestForm.${keyRange}`);
            response.isValid = false;
        }
    } else if (IsSameHours(startDate,
        endDate)) {
        const nameDayLabel = `tripRequestForm.${nameDay}`;

        response.message = `${localize(nameDayLabel)}: 
            ${localize('tripRequestForm.arrivalTimeAtNotIqualsSchoolPickupTimeFromSchool')}`;
        response.isValid = false;
    }

    return response;
};

const contactMissingFields = (tripModel, key) => {
    const fields = [
        tripModel[`${key}FirstName`],
        tripModel[`${key}Relationship`],
        tripModel[`${key}MobilePhone`],
        tripModel[`${key}AltPhone`],
        tripModel[`${key}EmailAddress`]
    ];

    const missingFields = [];

    // If any one of the fields for a contact has been filled in then
    // validate contact has FirstName, Relationship, and MobilePhone
    if (fields.some((field) => field)) {
        if (stringValidate(fields[0])) {
            missingFields.push(localize('tripRequestForm.name'));
        }
        if (stringValidate(fields[1])) {
            missingFields.push(localize('tripRequestForm.relationship'));
        }
        if (stringValidate(fields[2])) {
            missingFields.push(localize('tripRequestForm.mobilePhoneNumber'));
        }
    }

    return missingFields;
};

const isValidForm = (tripModel, emailErrors) => {
    const messages = [];
    let response = true;
    const currentDate = new Date();

    currentDate.setHours(
        23,
        59,
        59,
        0
    );
    currentDate.setDate(currentDate.getDate() - 1);

    if (!tripModel.studentDateOfBirth || !dateIsValid(tripModel.studentDateOfBirth)) {
        messages.push(localize('tripRequestForm.studentDateOfBirthFormatOrRequired'));
        response = false;
    }

    if (dateIsValid(tripModel.studentDateOfBirth) && compareIsBefore(currentDate,
        tripModel.studentDateOfBirth)) {
        messages.push(localize('tripRequestForm.studentBirthDateShouldNotAfterMaxDate'));
        response = false;
    }

    if (dateIsValid(tripModel.studentDateOfBirth) && compareIsBefore(tripModel.studentDateOfBirth,
        minimumDateAllowed())) {
        messages.push(localize('tripRequestForm.studentBirthDateMinDate'));
        response = false;
    }

    if (!isPhoneNumber(tripModel.parent1MobilePhone)) {
        messages.push(localize('tripRequestForm.emergencyContact1MobilePhoneFormatInvalid'));
        response = false;
    }

    if (!isPhoneNumber(tripModel.parent1AltPhone)) {
        messages.push(localize('tripRequestForm.emergencyContact1AltPhoneFormatInvalid'));
        response = false;
    }

    if (!isPhoneNumber(tripModel.contact1MobilePhone)) {
        messages.push(localize('tripRequestForm.emergencyContact2MobilePhoneFormatInvalid'));
        response = false;
    }

    if (!isPhoneNumber(tripModel.contact1AltPhone)) {
        messages.push(localize('tripRequestForm.emergencyContact2AltPhoneFormatInvalid'));
        response = false;
    }

    if (!isPhoneNumber(tripModel.contact2MobilePhone)) {
        messages.push(localize('tripRequestForm.emergencyContact3MobilePhoneFormatInvalid'));
        response = false;
    }

    if (!isPhoneNumber(tripModel.contact2AltPhone)) {
        messages.push(localize('tripRequestForm.emergencyContact3AltPhoneFormatInvalid'));
        response = false;
    }

    if (!isPhoneNumber(tripModel.schoolPhone)) {
        messages.push(localize('tripRequestForm.schoolPhoneFormatInvalid'));
        response = false;
    }

    if ((tripModel.tripStartDate && !dateIsValid(tripModel.tripStartDate)) ||
        (tripModel.requestType === requestTypeDto.new && !tripModel.tripStartDate)) {
        messages.push(localize('tripRequestForm.tripStartDateFormatOrRequired'));
        response = false;
    }

    if ((tripModel.tripEndDate && !dateIsValid(tripModel.tripEndDate)) ||
    (tripModel.requestType === requestTypeDto.new && !tripModel.tripEndDate)) {
        messages.push(localize('tripRequestForm.tripEndDateFormatOrRequired'));
        response = false;
    }

    if (tripModel.tripStartDate && dateIsValid(tripModel.tripStartDate)) {
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(
            0,
            0,
            0,
            0
        );
        if (compareIsSameOrBefore(tripModel.tripStartDate, currentDate)) {
            messages.push(localize('tripRequestForm.tripStartDateShouldNotAfterMaxDate'));
            response = false;
        }
    }

    if (tripModel.tripEndDate && dateIsValid(tripModel.tripEndDate)) {
        if (tripModel.tripStartDate && dateIsValid(tripModel.tripStartDate)) {
            currentDate.setDate(tripModel.tripStartDate.getDate() - 1);
            currentDate.setHours(
                0,
                0,
                0,
                0
            );
        } else {
            currentDate.setDate(currentDate.getDate() + 1);
            currentDate.setHours(
                0,
                0,
                0,
                0
            );
        }
        
        if (compareIsSameOrBefore(tripModel.tripEndDate, currentDate)) {
            messages.push(localize('tripRequestForm.tripEndDateShouldBeGreaterThanStartDate'));
            response = false;
        }
    }

    if (tripModel.accountId.trim() === '') {
        messages.push(localize('tripRequestForm.accountNumberIsRequired'));
        response = false;
    }

    if (tripModel.requestType === requestTypeDto.new &&
        tripModel.transportationEquipmentNeeds.length === 0) {
        messages.push(localize('tripRequestForm.transportationNeedsIsRequired'));
        response = false;
    }

    if (tripModel.schoolMonday) {
        const responseValidation = validationSchoolDays(tripModel.schoolArrivalMonday,
            tripModel.schoolPickupMonday,
            'schoolMondayIsRequired',
            'schoolMondayRangeInvalid',
            'monday');

        if (!responseValidation.isValid) {
            messages.push(responseValidation.message);
            response = false;
        }
    }
    if (tripModel.schoolTuesday) {
        const responseValidation = validationSchoolDays(tripModel.schoolArrivalTuesday,
            tripModel.schoolPickupTuesday,
            'schoolTuesdayIsRequired',
            'schoolTuesdayRangeInvalid',
            'tuesday');

        if (!responseValidation.isValid) {
            messages.push(responseValidation.message);
            response = false;
        }
    }
    if (tripModel.schoolWednesday) {
        const responseValidation = validationSchoolDays(tripModel.schoolArrivalWednesday,
            tripModel.schoolPickupWednesday,
            'schoolWednesdayIsRequired',
            'schoolWednesdayRangeInvalid',
            'wednesday');

        if (!responseValidation.isValid) {
            messages.push(responseValidation.message);
            response = false;
        }
    }
    if (tripModel.schoolThursday) {
        const responseValidation = validationSchoolDays(tripModel.schoolArrivalThursday,
            tripModel.schoolPickupThursday,
            'schoolThursdayIsRequired',
            'schoolThursdayRangeInvalid',
            'thursday');

        if (!responseValidation.isValid) {
            messages.push(responseValidation.message);
            response = false;
        }
    }
    if (tripModel.schoolFriday) {
        const responseValidation = validationSchoolDays(tripModel.schoolArrivalFriday,
            tripModel.schoolPickupFriday,
            'schoolFridayIsRequired',
            'schoolFridayRangeInvalid',
            'friday');

        if (!responseValidation.isValid) {
            messages.push(responseValidation.message);
            response = false;
        }
    }
    if (tripModel.schoolSaturday) {
        const responseValidation = validationSchoolDays(tripModel.schoolArrivalSaturday,
            tripModel.schoolPickupSaturday,
            'schoolSaturdayIsRequired',
            'schoolSaturdayRangeInvalid',
            'saturday');

        if (!responseValidation.isValid) {
            messages.push(responseValidation.message);
            response = false;
        }
    }
    if (tripModel.schoolSunday) {
        const responseValidation = validationSchoolDays(tripModel.schoolArrivalSunday,
            tripModel.schoolPickupSunday,
            'schoolSundayIsRequired',
            'schoolSundayRangeInvalid',
            'sunday');

        if (!responseValidation.isValid) {
            messages.push(responseValidation.message);
            response = false;
        }
    }

    if(emailErrors.parent1EmailAddress) {
        messages.push(localize('userForm.InvalidFormatEmail'));
        response = false;
    }

    if(emailErrors.contact1EmailAddress) {
        messages.push(localize('userForm.InvalidFormatEmail'));
        response = false;
    }

    if(emailErrors.contact2EmailAddress) {
        messages.push(localize('userForm.InvalidFormatEmail'));
        response = false;
    }

    const contact2MissingFields = contactMissingFields(tripModel, 'contact1');

    if (contact2MissingFields.length > 0) {
        const missing = contact2MissingFields.join(", ");

        messages.push(`${localize('tripRequestForm.emergencyContact2MissingFields')} ${missing.toLowerCase()}`);
        response = false;
    }

    const contact3MissingFields = contactMissingFields(tripModel, 'contact2');

    if (contact3MissingFields.length > 0) {
        const missing = contact3MissingFields.join(", ");

        messages.push(`${localize('tripRequestForm.emergencyContact3MissingFields')} ${missing.toLowerCase()}`);
        response = false;
    }

    return {
        response,
        messages
    };
};

export const onAccountEmailValidation = () => (dispatch, getState) => {
    const { tripModel } = getState().TripRequestFormReducers;

    if (tripModel.accountId) {
        const selectedAccount = Auth.getAccounts().find(x => x.AccountGUID === tripModel.accountId);

        if (!selectedAccount.Email) {
            dispatch(setSelectedAccountHasNoEmail(true));
            dispatch(setErrorMessage([]));

            return false;
        }
    }
    
    dispatch(setSelectedAccountHasNoEmail(false));

    return true;
};

export const resetValues = () => dispatch => {
    dispatch(isNewCorrect());
    dispatch(setErrorMessage([]));
    dispatch(setIsSameAddressCheck(false));
    dispatch(setIsSameContactCheck(false));
    dispatch(setIsSameSchoolCheck(false));
    dispatch(setIsSameTripInfoCheck(false));
    dispatch(setKeyDropZone(Math.random().toString()));
    dispatch(closeStyledSnackBar());
    dispatch(setPopulationTypes(populationTypeDto));
    dispatch(setSchedule(scheduleModel));
    dispatch(setSchoolDays(schoolDaysDto));
    dispatch(setSelectedHomeAddress({}));
    dispatch(setSelectedHomeAddress2({}));
    dispatch(setSelectedDropOffAddress({}));
    dispatch(setSelectedDropOffAddress2({}));
    dispatch(setSelectedPickUpAddress({}));
    dispatch(setSelectedPickUpAddress2({}));
    dispatch(setSelectedSchool({}));
    dispatch(setSelectedSchoolAddress({}));
    dispatch(setSelectedSchoolAddress2({}));
    dispatch(setShowDifferentAddress({
        pk: false,
        do: false
    }));
    dispatch(setStudentAttachments([]));
    dispatch(setOldDraftStudentAttachments([]));
    dispatch(setUploadedDraftAttachments([]));
    dispatch(setTransportationNeeds(transportationEquipmentDto));
    dispatch(setEmailErrors({
        parent1EmailAddress: false,
        contact1EmailAddress: false,
        contact2EmailAddress: false
    }));
    dispatch(setEnabledSchoolInput({
        addressCity: false,
        addressZipCode: false,
        addressState: false,
        schoolPhone: false,
        schoolName: false
    }));
    dispatch(setIsDraftSelected(false));
    dispatch(setStudentStartDateErrorMessage(''));
    dispatch(setStudentEndDateErrorMessage(''));
    dispatch(setStudentBirthDateErrorMessage(''));
    dispatch(setNonSchoolAddressList([]));
    dispatch(setSelectedPreviousHomeAddress(""));
    dispatch(setSelectedPreviousDropOffAddress(""));
    dispatch(setSelectedPreviousPickUpAddress(""));
};

export const onAddForm = () => dispatch => {
    const model = {
        ...personalInformationModel,
        ...generalTripInformationModel,
        ...schoolInformationModel,
        ...emergencyContactInformationModel
    };

    initialValues(model);
    dispatch(updateModel(model));
    dispatch(setEnabledStudentDate(false));
    dispatch(setDefaultStudentId(''));
    dispatch(setSearchTerm(''));
    dispatch(resetValues());
    dispatch(setSelectedStudent({}));
    dispatch(setSelectedHomeAddress({}));
    dispatch(setSelectedHomeAddress2({}));
    dispatch(updateOldDraftModel({}));
    dispatch(setOldDraftStudentAttachments([]));
    dispatch(setOldDraftPopulationTypes(populationTypeDto));
    dispatch(setOldDraftTransportationNeeds(transportationEquipmentDto));
    dispatch(setSelectedAccountHasNoEmail(false));
    dispatch(setErrorMessage([]));
};

export const onOpenSubmitModal = tabNumber => dispatch => {
    const submitMessage = localize('tripRequestForm.SubmitFormConfirm').replace(
        '#Number',
        tabNumber
    );

    dispatch(setSubmitConfirmModalStatus(true));
    dispatch(setSubmitFormMessage(submitMessage));
};

const submitTripRequestForm = (requestDto) => (dispatch, getState) => {
    dispatch(tripRequestFormResponseFile({ status: '' }));

    const body = JSON.stringify(requestDto);
    const hasTripTrackAccess = Auth.hasTripTrackingAccess();

    mrmFetch(`/api/v2/Request`,
        'post',
        null,
        body
    ).then((resp) => {
        dispatch(setTripRequestFormLoading(false));
        if (Array.isArray(resp)) {
            const { currentSibling, siblingNumber } = getState().TripRequestFormReducers;

            dispatch(onAddForm());

            dispatch(setSnackbar(
                localize('tripRequestForm.messageSuccess'),
                Enum.severityType.success
            ));

            if (siblingNumber === 1) {
                if (hasTripTrackAccess) {
                    dispatch(searchStudent('', true));
                    dispatch(onAddForm());
                }
            } else {
                dispatch(onDeleteTab(currentSibling));
            }
        } else {
            dispatch(setSnackbar(
                localize(errorLabel),
                Enum.severityType.error
            ));
        }
        dispatch(openStyledSnackBar());
        setTimeout(
            () => {
                dispatch(closeStyledSnackBar());
            },
            2000
        );
    }).catch(e => {
        dispatch(setTripRequestFormLoading(false));

        dispatch(setSnackbar(
            localize(errorLabel),
            Enum.severityType.error
        ));

        dispatch(openStyledSnackBar());
        window.warn(`error in submitTripRequestForm`,
            e
        );
    });
};

const uploadRequestFile = (files, source) => async (dispatch) => {
    dispatch(setTripRequestFormLoading(true));
    let indexProcess = 1;

    if (files.length === 0) {
        dispatch(submitTripRequestForm(source));
    } else {
        const requestData = source;

        requestData.studentAttachments = [];
        // eslint-disable-next-line
        for (const file of files) {
            const formData = new FormData();

            formData.append('file',
                file
            );
            try {
                // eslint-disable-next-line
                const resp = await mrmFetchFormData(`/api/v2/RequestFile`,
                    formData,
                    'post');

                requestData.studentAttachments.push(resp.url);
                if (indexProcess === files.length) {
                    dispatch(submitTripRequestForm(requestData));
                }
                indexProcess += 1;
            } catch (e) {
                dispatch(setTripRequestFormLoading(false));
                dispatch(setSnackbar(
                    localize(errorLabel),
                    Enum.severityType.error
                ));
                dispatch(openStyledSnackBar());
                window.warn('error in uploadRequestFile',
                    e
                );
            }
        }
    }
};

export const onLoadDefaultStudent = (locationState) => (dispatch) => {
    dispatch(setSearchTerm(locationState.studentCode));
    dispatch(setDefaultStudentId(locationState.studentId));
};

export const onUpdateModel = (evt) => async (dispatch, getState) => {
    const { tripModel, calledGetCorrelationId } = getState().TripRequestFormReducers;
    const { name, type } = evt.target;
    const accounts = Auth.getAccounts();
    let { value } = evt.target;
    
    if (tripModel.correlationId === '' && calledGetCorrelationId === false) {
        (async () => {
            dispatch(setCalledGetCorrelationId(true));
        
            window.log("calling API to get CorrelationId");
            const response = await getCorrelationId();
    
            if (response?.correlationId) {
                dispatch(updateModel({correlationId: response.correlationId}));
                window.log("CorrelationId set to", response.correlationId);
            } else {
                window.log("Error getting CorrelationId");
            }
        })();
    }

    if (type === 'checkbox') {
        value = evt.target.checked;
    }

    if (name === 'studentId') {
        dispatch(setIsDraftSelected(false));
    }

    if (name === 'accountNumber') {
        const account = accounts.find(r => r.AccountID === value);

        dispatch(updateModel({
            accountId: account.AccountGUID,
            accountNumber: value,
            accountName: account.Name
        }));
    } else {
        dispatch(updateModel({
            [name]: name === 'studentId' ? value.trim() : value
        }));

        switch (name) {
        case 'parent1EmailAddress':
            dispatch(parentAppAccess(value, 'parent1AppAccess'));
            break;
        case 'contact1EmailAddress':
            dispatch(parentAppAccess(value, 'contact1AppAccess'));
            break;
        case 'contact2EmailAddress':
            dispatch(parentAppAccess(value, 'contact2AppAccess'));
            break;
        default:
            break;
        }
    }

    dispatch(isNewCorrect());
};

export const onSubmitTripForm = () => async (dispatch, getState) => {
    dispatch(formatPopulationTypeGeneralTripInfo());
    dispatch(formatTransporationNeeds());

    const { tripModel, attachStudentDocuments, emailErrors, isDraftSelected } = getState().TripRequestFormReducers;
    const validForm = isValidForm(tripModel, emailErrors);

    if (validForm.response) {
        tripModel.isDraftSelected = isDraftSelected;
        formatPhoneNumbers(tripModel);
        formatDays(tripModel);
        dispatch(uploadRequestFile(attachStudentDocuments,
            tripModel
        ));
    } else {
        dispatch(setErrorMessage(validForm.messages));
    }

    dispatch(setSubmitConfirmModalStatus(false));
};

export const onLoadForm = () => dispatch => {
    dispatch(cleanForm());
    dispatch(onAddForm());
    dispatch(searchStudent('', true));
};
