import React, { useEffect, useState, useTransition } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import DPApp from 'components/DPApp';
import StudentOnBoardingReportHeader from 'components/report/StudentOnBoardingReportHeader';
import StudentOnBoardingGroup from 'components/report/StudentOnBoardingGroup/StudentOnBoardingGroup';
import { localize } from 'util/Localizer';
import StyledLoading from "components/Common/StyledLoading/StyledLoading";
import {
    Enum,
    optionsFilterStudentOnBoardingGroup as options
} from 'util/EnumHelper';

import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import Auth from 'auth/Auth';
import StyledContentBox from 'components/Common/StyledContentBox/StyledContentBox';

function StudentOnBoardingReport({
    report,
    loadReport,
    downloadReport: download,
    lastModfiedDate,
    filterCards,
    resetReponses,
    term,
    cardElements,
    setTermReport: searchStudent,
    fetchStudentReport: studentSearch,
    isLoadingReport,
    accessPathMiddleware
}) {
    const [ onHoldCount, setOnHoldCount ] = useState(0);
    const [ inProgressCount, setInProgressCount ] = useState(0);
    const [ activeCount, setActiveCount ] = useState(0);
    const [ status, setStatus ] = useState({ onHold: null });
    const [ groupings, setGroupings ] = useState({ onHold: false, inProgress: false, active: false });
    const [ searchResultDom, setSearchResultDom ] = useState("");
    const [ isPending, startTransition ] = useTransition();

    const sortList = (statusFilter) => {
        const filterElements = cardElements.filter(card => card.EverythingComplete.trim().startsWith(statusFilter));

        return filterElements.sort((first, second) => first.StudentName.localeCompare(second.StudentName));
    };

    const onStudentSearch = (studentName) => {
        accessPathMiddleware(Auth.getConfig().areReportsEnabled);
        studentSearch(studentName);
    };

    const onClickDownload = () => {
        accessPathMiddleware(Auth.getConfig().areReportsEnabled);
        download(report, true);
    };

    const getCards = type => {
        switch (type) {
        case Enum.reportStatus.onHold.key:
            return sortList(Enum.reportStatus.onHold.value);
        case Enum.reportStatus.inProgress.key:
            return sortList(Enum.reportStatus.inProgress.value);
        case Enum.reportStatus.active.key:
            return sortList(Enum.reportStatus.active.value);
        default:
            return [];
        }
    };

    const getFilterList = type => {
        if (type === Enum.reportStatus.onHold.key) {
            const onHoldList = sortList(Enum.reportStatus.onHold.value);
            const filterListValue = [...new Set(onHoldList.map(item => item.EverythingComplete.trim()))];

            return filterListValue.map(x => ({ name: null, value: x }));
        }

        return [];
    };

    const onChangeStatusFilter = (groupType, currentFilter) => {
        setStatus({ ...status, [groupType]: currentFilter });
    };

    const onChangeGrouping = (groupType, value) => {
        setGroupings({...groupings, [groupType]: value });
    };

    useEffect(() => {
        resetReponses();
        loadReport(report);
    }, []);

    useEffect(() => {
        filterCards();
    }, [ term, isLoadingReport ]);

    useEffect(() => {
        setOnHoldCount(sortList(Enum.reportStatus.onHold.value).length);
        setInProgressCount(sortList(Enum.reportStatus.inProgress.value).length);
        setActiveCount(sortList(Enum.reportStatus.active.value).length);

        const list = options.map(
            groupType => (
                <StudentOnBoardingGroup
                    key={groupType.key}
                    groupType={groupType.key}
                    cardElements={getCards(groupType.key)}
                    filterList={getFilterList(groupType.key)}
                    showGroupBtn
                    changeFilter={onChangeStatusFilter}
                    groupBySchool={groupings[groupType.key]}
                    changeGrouping={onChangeGrouping}
                    filter={status[groupType.key]}
                    onHandleStudentSearch={onStudentSearch}
                />)
        );

        startTransition(() => {
            setSearchResultDom(list);
        });
    }, [ cardElements, status, groupings ]);

    if (isLoadingReport) {
        return (
            <DPApp>
                <StyledContentBox>
                    <StyledLoading message={localize("report.loadingStudentOnboardingReport")} />
                </StyledContentBox>
            </DPApp>
        );
    }

    return (
        <DPApp>
            <StyledContentBox>
                <Grid container item xs={12}>
                    <Grid item xs={12} md={6}>
                        <StyledTypography>
                            {localize('report.studentOnBoarding')}
                        </StyledTypography>
                    </Grid>
                </Grid>
                <StudentOnBoardingReportHeader
                    lastModfiedDate={lastModfiedDate}
                    filterTerm={term}
                    onSearchStudent={searchStudent}
                    onClickDownload={onClickDownload}
                    onHoldCount={onHoldCount}
                    inProgressCount={inProgressCount}
                    activeCount={activeCount}
                />
                {!isPending && searchResultDom}
                {isPending && <StyledLoading message={localize("report.loadingStudentOnboardingReport")} />}
            </StyledContentBox>
        </DPApp>
    );
}

StudentOnBoardingReport.defaultProps = {
    report: {},
    loadReport: () => {},
    downloadReport: () => {},
    lastModfiedDate: '',
    filterCards: () => {},
    resetReponses: () => {},
    term: '',
    cardElements: [],
    setTermReport: () => {},
    fetchStudentReport: () => {},
    isLoadingReport: false,
    accessPathMiddleware: () => {}
};

/* eslint-disable react/forbid-prop-types */
StudentOnBoardingReport.propTypes = {
    report: PropTypes.object,
    loadReport: PropTypes.func,
    downloadReport: PropTypes.func,
    lastModfiedDate: PropTypes.string,
    filterCards: PropTypes.func,
    resetReponses: PropTypes.func,
    term: PropTypes.string,
    cardElements: PropTypes.array,
    setTermReport: PropTypes.func,
    fetchStudentReport: PropTypes.func,
    isLoadingReport: PropTypes.bool,
    accessPathMiddleware: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default StudentOnBoardingReport;
