import { scheduleValidModel } from "../../data/models/tripRequestForm/TripRequestFormModel";
import { Enum } from "../../util/EnumHelper";
import { localize } from "../../util/Localizer";
import {
    insertArrayForm,
    removeArrayForm,
    setCurrentSibling,
    setDefaultStudentId,
    setEmailErrors,
    setEnabledSchoolInput,
    setEnabledStudentDate,
    setIsDraftForSave,
    setIsDraftSelected,
    setIsSameAddressCheck,
    setIsSameContactCheck,
    setIsSameSchoolCheck,
    setIsSameTripInfoCheck,
    setKeyDropZone,
    setLimitReached,
    setNonSchoolAddressList,
    setOldDraftPopulationTypes,
    setOldDraftStudentAttachments,
    setOldDraftTransportationNeeds,
    setPopulationTypes,
    setRemoveSiblingModalStatus,
    setRemoveTabMessage,
    setSchedule,
    setScheduleValid,
    setSchoolDays,
    setSearchTerm,
    setSelectedAccountHasNoEmail,
    setSelectedDropOffAddress,
    setSelectedDropOffAddress2,
    setSelectedHomeAddress,
    setSelectedHomeAddress2,
    setSelectedPickUpAddress,
    setSelectedPickUpAddress2,
    setSelectedPreviousDropOffAddress,
    setSelectedPreviousHomeAddress,
    setSelectedPreviousPickUpAddress,
    setSelectedSchool,
    setSelectedSchoolAddress,
    setSelectedSchoolAddress2,
    setSelectedStudent,
    setShowDifferentAddress,
    setSiblingNumber,
    setStudentAttachments,
    setStudentBirthDateErrorMessage,
    setStudentEndDateErrorMessage,
    setStudentStartDateErrorMessage,
    setTabForDelete,
    setTransportationNeeds,
    setUploadedDraftAttachments,
    updateArrayForm,
    updateModel,
    updateOldDraftModel
} from "../actions/tripRequestFormActions";
import { maxTabNumber } from "../constants";

// eslint-disable-next-line import/no-cycle
import { isNewCorrect, onAddForm } from "./tripRequestFormWorker";

import {
    openStyledSnackBar,
    setSnackbar
} from './styledSnackbarWrapperWorker';

import { cleanUploadStudent } from "./studentUploadWorker";

const cleanAllScheduleInputs = () => {
    const arrivalInputs = document.querySelectorAll('[id^="schoolArrival"]');
    const pickUpInputs = document.querySelectorAll('[id^="schoolPickup"]');

    arrivalInputs.forEach(input => {
        const arrivalInput = input;

        arrivalInput.value = '';
    });
    pickUpInputs.forEach(input => {
        const pickUpInput = input;

        pickUpInput.value = '';
    });
};

const onLoadTab = (tabNumber) => (dispatch, getState) => {
    const { tripModels } = getState().TripRequestFormReducers;

    const form = tripModels.find(x => x.student === tabNumber);

    cleanAllScheduleInputs();

    if (form && form.student) {
        dispatch(setEnabledSchoolInput(form.enabledSchoolInputs));
        dispatch(setEmailErrors(form.emailErrors));
        dispatch(setPopulationTypes(form.populationTypes));
        dispatch(setTransportationNeeds(form.transportationEquipmentNeeds));
        dispatch(setSchoolDays(form.schoolDays));
        dispatch(setSchedule(form.schedule));
        dispatch(setShowDifferentAddress(form.showDifferentAddress));
        dispatch(setStudentAttachments(form.attachStudentDocuments));
        dispatch(setUploadedDraftAttachments(form.uploadedDraftAttachments));
        dispatch(setKeyDropZone(form.keyDropZone));
        dispatch(setDefaultStudentId(form.defaultStudentId));
        dispatch(setSearchTerm(form.searchTerm));
        dispatch(setSelectedHomeAddress(form.selectedHomeAddress));
        dispatch(setSelectedHomeAddress2(form.selectedHomeAddress2));
        dispatch(setSelectedPickUpAddress(form.selectedPickUpAddress));
        dispatch(setSelectedPickUpAddress2(form.selectedPickUpAddress2));
        dispatch(setSelectedDropOffAddress(form.selectedDropOffAddress));
        dispatch(setSelectedDropOffAddress2(form.selectedDropOffAddress2));
        dispatch(setSelectedSchool(form.selectedSchool));
        dispatch(setSelectedSchoolAddress(form.selectedSchoolAddress));
        dispatch(setSelectedSchoolAddress2(form.selectedSchoolAddress2));
        dispatch(setSelectedStudent(form.selectedStudent));
        dispatch(setIsSameAddressCheck(form.isSameAddress));
        dispatch(setIsSameTripInfoCheck(form.isSameTripInformation));
        dispatch(setIsSameContactCheck(form.isSameContacts));
        dispatch(setIsSameSchoolCheck(form.isSameSchool));
        dispatch(setEnabledStudentDate(form.isEnabledStudentDate));
        dispatch(setIsDraftSelected(form.isDraftSelected));
        dispatch(setStudentBirthDateErrorMessage(form.studentBirthDateErrorMessage));
        dispatch(setStudentStartDateErrorMessage(form.studentStartDateErrorMessage));
        dispatch(setStudentEndDateErrorMessage(form.studentEndDateErrorMessage));
        dispatch(updateModel(form.tripModel));
        dispatch(updateOldDraftModel(form.oldDraftModel));
        dispatch(setScheduleValid(scheduleValidModel));
        dispatch(isNewCorrect());
        dispatch(setIsDraftForSave(form.isDraftForSave));
        dispatch(setOldDraftStudentAttachments(form.oldDraftAttachments));
        dispatch(setOldDraftPopulationTypes(form.oldDraftPopulationTypes));
        dispatch(setOldDraftTransportationNeeds(form.oldDraftTransportationEquipmentNeeds));
        dispatch(setNonSchoolAddressList(form.nonSchoolAddressList));
        dispatch(setSelectedPreviousHomeAddress(form.selectedPreviousHomeAddress));
        dispatch(setSelectedPreviousPickUpAddress(form.selectedPreviousPickUpAddress));
        dispatch(setSelectedPreviousDropOffAddress(form.selectedPreviousDropOffAddress));
        dispatch(setSelectedAccountHasNoEmail(form.selectedAccountHasNoEmail));
    } else {
        dispatch(onAddForm());
    }
};

export const onAddNewTab = () => (dispatch, getState) => {
    const { siblingNumber } = getState().TripRequestFormReducers;
    const { isCompleted } = getState().StudentUploadReducers;

    if(isCompleted) {
        dispatch(cleanUploadStudent());
    } else {
        const newNumber = parseInt(siblingNumber,
            10) + 1;
    
        if (newNumber > maxTabNumber) {
            dispatch(setLimitReached(true));
        } else if (newNumber === maxTabNumber) {
            dispatch(setSiblingNumber(newNumber));
            dispatch(setLimitReached(true));
        } else {
            dispatch(setLimitReached(false));
            dispatch(setSiblingNumber(newNumber));
        }
    }
};

export const onSelectNewTab = (tabNumber, currentSibling) => (dispatch, getState) => {
    const {
        tripModels,
        tripModel,
        enabledSchoolInputs,
        emailErrors,
        populationTypes,
        transportationEquipmentNeeds,
        schoolDays,
        schedule,
        showDifferentAddress,
        attachStudentDocuments,
        uploadedDraftAttachments,
        keyDropZone,
        defaultStudentId,
        selectedHomeAddress,
        selectedHomeAddress2,
        selectedPickUpAddress,
        selectedPickUpAddress2,
        selectedDropOffAddress,
        selectedDropOffAddress2,
        selectedSchool,
        selectedSchoolAddress,
        selectedSchoolAddress2,
        selectedStudent,
        searchTerm,
        isSameAddress,
        isSameTripInformation,
        isSameContacts,
        isSameSchool,
        isEnabledStudentDate,
        studentStartDateErrorMessage,
        studentBirthDateErrorMessage,
        studentEndDateErrorMessage,
        isDraftForSave,
        oldDraftModel,
        oldDraftAttachments,
        oldDraftPopulationTypes,
        oldDraftTransportationEquipmentNeeds,
        isDraftSelected,
        nonSchoolAddressList,
        selectedPreviousHomeAddress,
        selectedPreviousPickUpAddress,
        selectedPreviousDropOffAddress,
        selectedAccountHasNoEmail
    } = getState().TripRequestFormReducers;

    const currentForm = {
        student: currentSibling,
        tripModel,
        emailErrors,
        enabledSchoolInputs,
        populationTypes,
        transportationEquipmentNeeds,
        schoolDays,
        schedule,
        showDifferentAddress,
        attachStudentDocuments,
        uploadedDraftAttachments,
        keyDropZone,
        defaultStudentId,
        selectedHomeAddress,
        selectedHomeAddress2,
        selectedPickUpAddress,
        selectedPickUpAddress2,
        selectedDropOffAddress,
        selectedDropOffAddress2,
        selectedSchool,
        selectedSchoolAddress,
        selectedSchoolAddress2,
        selectedStudent,
        searchTerm,
        isSameAddress,
        isSameTripInformation,
        isSameContacts,
        isSameSchool,
        isEnabledStudentDate,
        studentStartDateErrorMessage,
        studentBirthDateErrorMessage,
        studentEndDateErrorMessage,
        isDraftForSave,
        oldDraftModel,
        oldDraftAttachments,
        oldDraftPopulationTypes,
        oldDraftTransportationEquipmentNeeds,
        isDraftSelected,
        nonSchoolAddressList,
        selectedPreviousHomeAddress,
        selectedPreviousPickUpAddress,
        selectedPreviousDropOffAddress,
        selectedAccountHasNoEmail
    };

    dispatch(cleanUploadStudent());

    const validForm = tripModels.find(x => x.student === currentSibling);

    if ((currentForm.tripModel.studentDateOfBirth && Number.isNaN(currentForm.tripModel.studentDateOfBirth.getTime()))
        || (currentForm.tripModel.tripStartDate && Number.isNaN(currentForm.tripModel.tripStartDate.getTime()))
        || (currentForm.tripModel.tripEndDate && Number.isNaN(currentForm.tripModel.tripEndDate.getTime()))) {
        dispatch(setSnackbar(
            localize('tripRequestForm.invalidDateAlertSwitchingTabs'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());

        return false;
    }

    if (validForm && validForm.student) {
        dispatch(updateArrayForm(currentForm));
    } else {
        dispatch(insertArrayForm(currentForm));
    }

    dispatch(onLoadTab(tabNumber));
    dispatch(setCurrentSibling(tabNumber));

    return true;
};

export const onDeleteTab = (tabForDelete) => (dispatch, getState) => {
    const { siblingNumber, currentSibling } = getState().TripRequestFormReducers;
    const newNumber = parseInt(siblingNumber,
        10) - 1;

    dispatch(setLimitReached(false));
    dispatch(setSiblingNumber(newNumber));
    dispatch(setRemoveSiblingModalStatus(false));
    dispatch(removeArrayForm(tabForDelete));

    if (currentSibling === tabForDelete) {
        if (currentSibling === 1) {
            dispatch(onLoadTab(1));
        } else {
            dispatch(onLoadTab(tabForDelete - 1));
        }
    }

    if (tabForDelete <= currentSibling) {
        if (currentSibling === 1) {
            dispatch(setCurrentSibling(1));
        } else {
            dispatch(setCurrentSibling(currentSibling - 1));
        }
    }
};

export const onOpenDeleteTabModal = tabNumber => (dispatch) => {
    const deleteMessage = localize('tripRequestForm.RemoveSiblingConfirm').replace(
        '#Number',
        tabNumber
    );

    dispatch(setRemoveSiblingModalStatus(true));
    dispatch(setRemoveTabMessage(deleteMessage));
    dispatch(setTabForDelete(tabNumber));
};
